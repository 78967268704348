@import 'styles/_variables.scss';
@import 'components/Content/Content.module.scss';

.publicacoes{
    text-align: justify;
    width: 100%;    
    
    &__ilustra{
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 20px;
    margin-bottom: $padding-vertical;
    box-shadow: $sombra;
}
    &__texto{
    text-justify: auto;
    font-style: italic;
    font-size: medium;
    }
}

@media screen and (min-width: $break){
    .publicacoes{
        display:flex;
        width: 90%;
        margin: auto;
    
    &__texto{
        padding-left: calc(2*$padding-horizontal);
    }
}
}