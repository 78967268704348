@import 'styles/_variables.scss';

.assinatura{
    position: absolute;
    text-align: left;
    font-size: medium;
    display: flex;
    margin-top: calc(0.5*$padding-vertical);
    right: 10vw;
    padding-right: $padding-horizontal;
        
    &__foto{
        width: calc(1.3*$padding-vertical);        
        border-radius: 40%;
        margin-right: $padding-horizontal;
        box-shadow: $sombra;
    }
    &__texto p{

        font-size: x-large;
        font-style: italic;
        line-height: 100%;

    }
}

.espaco{
    height: 50px;
}

