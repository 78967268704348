$cor-primaria: #186279;
$cor-secundaria: #022437;
$cor-destaque: white;
$cor-contraste: white;
$cor-bg: #eef4f8fe;

$padding-horizontal: 10px;
$padding-vertical: 40px;
$navheigth: 56px;

$sombra: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 6px 10px 0 rgba(0, 0, 0, 0.3);

$break: 940px;