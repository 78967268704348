@import 'styles/variables.scss';
@import 'components/Content/Content.module.scss';

.profissional{
    &__box1{        
        justify-self: center;
        width:80%;
        margin: auto;
        display: flex;
        padding: 0 0 40px 0;
        justify-content: space-around;
    }
    &__box1 h1{
        padding-top: 20px;
        font-size:x-large;
    }
    &__fotoMini{
        width : 129px;
        height: 139px;       
        border-radius: 40%;
        object-fit: fill;
        margin-right: 8px;
        box-shadow: $sombra;
        cursor: pointer;
    }
  
}
// Resposividade
@media screen and (min-width: $break){	

    .profissional{
        display:flex;
        width:90%;
        margin: auto;
    
        &__box1{
            width: 50%;
            padding: 0 20px;
            border-right: 1px solid;
        }
        
        &__box2{
            width: 50%;
            padding: 0 20px;
        }
    }    
}
