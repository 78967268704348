
@import 'styles/_variables.scss';

.zoom{
    top: 20vh;
    width: 100%;
    justify-content: center;
    padding: $padding-vertical 0;
    position: fixed;
    z-index: 200; 
    background-color: $cor-primaria;
    border-radius: 10px;
    box-shadow: $sombra;

&__fechar{
    position: absolute;
    color:$cor-secundaria;
    font-size: 60px;
    right:2px;
    top: 2px;
    background-color: $cor-destaque;
    border-radius: 50%;    
}

&__foto{
    width: 95%;
    border-radius: 10px;
    margin: auto;          
}
&__desc{
    text-align: center;
    color: white;
    padding-top: 10px;
    width:95vw;
    font-size: medium;
}
}

@media screen and (min-width: $break){
    .zoom{
    width: 60vw;
    top: 10vh;
    margin-left: 10vw;
    &__foto{
    height: 70vh;
    width: 58vw;
    object-fit: cover;
    }
  }
}