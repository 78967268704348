@import 'styles/_variables.scss';

.naoEncontrada{
    margin: $padding-vertical $padding-horizontal ;
    background-color: $cor-secundaria;
    border-radius: 20px;
    color: $cor-contraste;
    padding: 18vh $padding-horizontal ;
    &__titulo{
        font-size: 40px;
        text-align: center;
    }
    &__titulo p{
        font-size: small;
        padding-top: $padding-vertical;
        
    }
    &__titulo button{
        border-radius: 8px;
    }
    
}